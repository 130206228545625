import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ImageService } from 'src/app/services/image.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-social-media',
    templateUrl: './social-media.component.html',
    styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {

    loading = false;
    loadingBtn = false;
    showPopup = false;
    modalBoxName = '';
    sno = 1;
    errorMsg: any = [];
    viewBox = false;
    showImage = true;
    show: boolean = false;
    socialmedia:any;
    email_link:any;
    instagram_link:any;
    facebook_link:any;
    call_link:any;
    description:any;
    youtube_link:any;
    whatsapp_link:any;
    id:any;

    constructor(
        private apiService: ApiService,
        private toastr: ToastrService,
        public router: Router,
        private imageService: ImageService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.getsocialmedia();
    }

    getsocialmedia() {
        this.loading = true;
        this.apiService.getData('getSocialMedia').subscribe((data) => {
            this.socialmedia = data.data[0];
            this.id = this.socialmedia.id;
            this.instagram_link = this.socialmedia.instagram_link;
            this.facebook_link = this.socialmedia.facebook_link;
            this.description = this.socialmedia.description;
            this.call_link = this.socialmedia.call_link;
            this.whatsapp_link = this.socialmedia.whatsapp_link;
            this.youtube_link = this.socialmedia.youtube_link;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    apiCall(name, url, value: any = '') {
        this.apiService[name](url, value).subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.loadingBtn = false;
            } else {
                this.toastr.error(data.message);
                this.loadingBtn = false;
                this.errorMsg = data.data;
            }
            this.getsocialmedia();
        });
    }

    updateHeader() {
        const value = {
            id:this.id,
            email_link: this.email_link,
            instagram_link: this.instagram_link,
            facebook_link: this.facebook_link,
            description: this.description,
            call_link: this.call_link,
            youtube_link: this.youtube_link,
            whatsapp_link: this.whatsapp_link,
        }
            this.apiCall("update", "updateSocialMedia", value);
    }

    closepopup() {
        this.show = false;
    }

    openPopup() {
        this.show = true;
    }
}
