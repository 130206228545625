import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ImageService } from 'src/app/services/image.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home-page-title',
    templateUrl: './home-page-title.component.html',
    styleUrls: ['./home-page-title.component.css']
})
export class HomePageTitleComponent implements OnInit {

    loading = false;
    loadingBtn = false;
    showPopup = false;
    modalBoxName = '';
    sno = 1;
    errorMsg: any = [];
    viewBox = false;
    showImage = true;
    show: boolean = false;
    Homepagetitle:any;
    banner_id:any;
    title_one: any;
    title_two:any;
    title_three: any;
    title_four: any;
    video_one: any;
    video_two: any;

    constructor(
        private apiService: ApiService,
        private toastr: ToastrService,
        public router: Router,
        private imageService: ImageService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.getWhoAreWe();
    }

    getWhoAreWe() {
        this.loading = true;
        this.apiService.getData('getHomePageTitle').subscribe((data) => {
            this.Homepagetitle = data.data[0];
            this.banner_id = this.Homepagetitle.id
            this.title_one = this.Homepagetitle.title_one;
            this.title_two = this.Homepagetitle.title_two;
            this.title_three = this.Homepagetitle.title_three;
            this.title_four = this.Homepagetitle.title_four;
            this.video_one = this.Homepagetitle.video_one;
            this.video_two = this.Homepagetitle.video_two;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    apiCall(name, url, value: any = '') {
        this.apiService[name](url, value).subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.loadingBtn = false;
            } else {
                this.toastr.error(data.message);
                this.loadingBtn = false;
                this.errorMsg = data.data;
            }
            this.getWhoAreWe();
        });
    }

    updateHeader() {
        const value = {
            id: this.banner_id,
            title_one: this.title_one,
            title_two: this.title_two,
            title_three: this.title_three,
            title_four: this.title_four,
            video_one: this.video_one,
            video_two: this.video_two,
        }
        this.apiCall("update", "updateHomePageTitle", value);
    }

    closepopup() {
        this.show = false;
    }

    openPopup() {
        this.show = true;
    }
}
