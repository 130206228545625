import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { ImageService } from 'src/app/services/image.service';
import Swal from 'sweetalert2';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
    selector: 'app-best-treatment',
    templateUrl: './best-treatment.component.html',
    styleUrls: ['./best-treatment.component.css']
})
export class BestTreatmentComponent implements OnInit {

    errorMsg: any = [];
    sno = 1;
    showPopup = false;
    loading = false;
    TreatmentList: any = [];
    header = '';
    description :any;
    editBannerId = '';
    modalBoxName;
    loadingBtn = false;
    viewBox = false;
    showImage = true;
    imageUrl = '';
    typename:any;
    defaultImage = 'assets/images/loader.gif';

    constructor(
        private apiService: ApiService,
        private toastr: ToastrService,
        private imageService: ImageService
    ) { }

    ngOnInit() {
        this.index();
        this.imageUrl = this.imageService.getImageUrl();
    }

    index() {
        this.loading = true;
        this.apiService.index('getAllBestTreatmentDetails').subscribe(data => {
            this.TreatmentList = data.data;
            this.loading = false;
        });
    }

    store() {
        this.loadingBtn = true;
        this.apiCall('store', 'bestTreatment');
    }

    show(id) {
        this.apiService.show('bestTreatment/' + id).subscribe((data) => {
            const value = data.data;
            this.description = value.description;
            this.editBannerId = id;
        });
    }

    update(id) {
        this.loadingBtn = true;
        this.apiCall('update', 'bestTreatment/' + id);
    }

    destroy(id) {
        this.swalCall(id);
    }

    bannerSwitch(id) {
        this.apiCall('getData', 'bestTreatmentSwitch/' + id);
    }

    apiCall(name, url, value: any = '') {
        value = name === 'store' || name === 'update' ? {
            description: this.description,
        } : value;
        this.apiService[name](url, value).subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.loadingBtn = false;
                this.popUpClose();
                this.index();
            } else {
                this.toastr.error(data.message);
                this.loadingBtn = false;
                this.errorMsg = data.data;
            }
        });
    }

    openModalBox(id = ''){
        if(id){
            this.modalBoxName = 'Edit';
            this.show(id);
        } else {
            this.modalBoxName = 'Create';
        }
        this.showPopup = true;
    }

    popUpClose() {
        this.showPopup = false;
        this.typename  = 0;
        this.editBannerId = '';
        this.description  = '';
        this.errorMsg = [];
        this.viewBox = false;
    }

    swalCall(id) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this.apiCall('destroy', 'bestTreatment/' + id);
                Swal.fire(
                    'Deleted!',
                    'Your data has been deleted.',
                    'success'
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your data is safe.',
                    'error'
                );
            }
        });
    }
editorConfig: AngularEditorConfig = {
        editable: true,
          spellcheck: true,
          height: 'auto',
          minHeight: '0',
          maxHeight: 'auto',
          width: 'auto',
          minWidth: '0',
          translate: 'yes',
          enableToolbar: true,
          showToolbar: true,
          placeholder: 'Enter text here...',
          defaultParagraphSeparator: '',
          defaultFontName: '',
          defaultFontSize: '',
          fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'},
            {class: 'poppins', name: 'Poppins'}
          ],
          customClasses: [
          {
            name: 'quote',
            class: 'quote',
          },
          {
            name: 'redText',
            class: 'redText'
          },
          {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
          },
        ],
        uploadUrl: 'v1/image',
        // upload: (file: File) => { ... }
        uploadWithCredentials: false,
        sanitize: false,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
          ['bold', 'italic'],
          ['fontSize']
        ]
    };
}
