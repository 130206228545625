import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxFileDropEntry,FileSystemDirectoryEntry,FileSystemFileEntry } from 'ngx-file-drop';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { ImageService } from 'src/app/services/image.service';

@Component({
    selector: 'app-gallery-image',
    templateUrl: './gallery-image.component.html',
    styleUrls: ['./gallery-image.component.css']
})
export class GalleryImageComponent implements OnInit {

    imageChangedEvent: any = '';
    croppedImage: any = '';
    loading: boolean;
    loadingBtn: boolean;
    public files: NgxFileDropEntry[] = [];
    imageWidth: number;
    imageHeigth: number;

    constructor(
        private apiService: ApiService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private imageService: ImageService,
        private imageCompress: NgxImageCompressService
    ) { }

    ngOnInit() {
    }

    public dropped(files: NgxFileDropEntry[]) {
        this.loading = true;
        this.files = [];
        this.files.push(files[files.length - 1]);
        for (const droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file(
                (ev) => {
                    this.imageChangedEvent = { target: { files: [ev] } };
                });
            } else {
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
            }
        }
    }

    deleteFile(index: number) {
        if (this.files.length !== 0) {
        this.files.splice(index, 1);
        }
        this.imageChangedEvent = '';
        this.croppedImage = '';
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.loading = false;
        var width, height, myBase64 = this.croppedImage;
      var img = new Image();
      img.src = myBase64;
      img.addEventListener('load',function(){
          width=img.width;
          height=img.height;
      });
      this.imageWidth = img.width;
      this.imageHeigth = img.height;
      if(this.imageWidth > 520 || this.imageHeigth > 380 ) {
        this.toastr.error('Image Size is Too High');
      }else if (this.imageWidth == 0 || this.imageHeigth == 0) {
        this.toastr.error('Image Size is Too High');
      }else if (this.croppedImage.length > 1409458) {
          this.toastr.error('Image Resolution is Too High');
      } else{
          this.toastr.success('Image Size is Correct');
      }
    }

    imageUpdateBanner(imageFile) {
        const formData: FormData = new FormData();
        formData.append('banner_id', this.route.snapshot.paramMap.get('id'));
        formData.append('banner_image', imageFile);
        this.apiService.postData(formData, 'imageUpdateGallery').subscribe(data => {
        if (data.error === false) {
            this.toastr.success(data.message);
            this.router.navigate(['/gallery']);
        } else {
            this.toastr.error(data.message);
        }
        this.loadingBtn = false;
        }, error => {
        this.loadingBtn = false;
        });
    }

    convertFile() {
        this.loadingBtn = true;
        this.imageCompress.compressFile(this.croppedImage, -1, 75, 50).then(
        result => {
            // console.log(result);
            var ImageURL = result;
            var block = ImageURL.split(';');
            var contentType = block[0].split(':')[1];
            var realData = block[1].split(',')[1];
            var blob = this.imageService.b64toBlob(realData, contentType);
            this.imageUpdateBanner(blob);
            // console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
        });
    }
}



