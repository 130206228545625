import { HttpClientModule, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NumberPickerModule } from 'ng-number-picker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UsersComponent } from './users/users.component';
import { MarkdownModule } from 'ngx-markdown';
import {NgxImageCompressService} from 'ngx-image-compress';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SubCategoryImageComponent } from './components/sub-category-image/sub-category-image.component';
import {MatSliderModule} from '@angular/material/slider';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { LightboxModule } from 'ngx-lightbox';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { HomebannerComponent } from './home/homebanner/homebanner.component';
import { HomebannerImageComponent } from './home/homebanner-image/homebanner-image.component';
import { SocialMediaComponent } from './home/social-media/social-media.component';
import { TestimonyComponent } from './home/testimony/testimony.component';
import { UserTypesComponent } from './user-types/user-types.component';
import { WhoAreWeComponent } from './home/who-are-we/who-are-we.component';
import { HomePageTitleComponent } from './home/home-page-title/home-page-title.component';
import { FacilitiesComponent } from './home/facilities/facilities.component';
import { GalleryComponent } from './home/gallery/gallery.component';
import { GalleryImageComponent } from './home/gallery-image/gallery-image.component';
import { ConsultUsComponent } from './home/consult-us/consult-us.component';
import { AboutUsComponent } from './about-us/about-us/about-us.component';
import { StatementComponent } from './about-us/statement/statement.component';
import { AdvancedTreatmentComponent } from './facilities/advanced-treatment/advanced-treatment.component';
import { BestTreatmentComponent } from './facilities/best-treatment/best-treatment.component';
import { BestTreatmentImageComponent } from './facilities/best-treatment-image/best-treatment-image.component';
import { ContactUsComponent } from './home/contact-us/contact-us.component';
import { AppointmentComponent } from './home/appointment/appointment.component';
import { SeoHomePageComponent } from './seo/seo-home-page/seo-home-page.component';
import { SeoAboutUsPageComponent } from './seo/seo-about-us-page/seo-about-us-page.component';
import { SeoFacilitiesPageComponent } from './seo/seo-facilities-page/seo-facilities-page.component';
import { SeoContactUsPageComponent } from './seo/seo-contact-us-page/seo-contact-us-page.component';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ConsultUsNumberComponent } from './home/consult-us-number/consult-us-number.component';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(err => this.handleError(err)));
    }

    private handleError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401 || err.status === 403) {
            this.router.navigateByUrl(`/`);
            return of(err.message);
        }
        return Observable.throw(err);
    }
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HeaderComponent,
        SidemenuComponent,
        UsersComponent,
        UnauthorizedComponent,
        PageNotFoundComponent,
        FooterComponent,
        SubCategoryImageComponent,
        HomebannerComponent,
        HomebannerImageComponent,
        SocialMediaComponent,
        TestimonyComponent,
        UserTypesComponent,
        WhoAreWeComponent,
        HomePageTitleComponent,
        FacilitiesComponent,
        GalleryComponent,
        GalleryImageComponent,
        ConsultUsComponent,
        AboutUsComponent,
        StatementComponent,
        AdvancedTreatmentComponent,
        BestTreatmentComponent,
        BestTreatmentImageComponent,
        ContactUsComponent,
        AppointmentComponent,
        SeoHomePageComponent,
        SeoAboutUsPageComponent,
        SeoFacilitiesPageComponent,
        SeoContactUsPageComponent,
        ConsultUsNumberComponent,
    ],

    imports: [
        MatSlideToggleModule,
        MatInputModule,
        MatCardModule,
        MatTabsModule,
        MatGridListModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        NgSelectModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        AutocompleteLibModule,
        BrowserAnimationsModule,
        NgxMaterialTimepickerModule,
        AngularEditorModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            positionClass: 'toast-top-right',
            timeOut: 3000,
            progressBar : true,
            progressAnimation: 'increasing',
        }),
        AppRoutingModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        TabsModule.forRoot(),
        AngularMultiSelectModule,
        NumberPickerModule,
        NgxLoadingModule.forRoot({
        animationType: ngxLoadingAnimationTypes.rectangleBounce,
            backdropBackgroundColour: '#fff',
            backdropBorderRadius: '25px',
            primaryColour: '#185698',
            secondaryColour: '#185698',
            tertiaryColour: '#185698'
        }),
        ImageCropperModule,
        NgxFileDropModule,
        LazyLoadImageModule,
        DragDropModule,
        MatSliderModule,
        MarkdownModule.forRoot(),
        ColorPickerModule,
        LightboxModule,

    ],
    providers: [
        NgxImageCompressService,{
            provide: HTTP_INTERCEPTORS,
            useFactory: function(router:Router) {
                return new AuthInterceptor(router)
            },
            multi: true,
            deps: [Router]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
