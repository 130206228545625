import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ImageService } from 'src/app/services/image.service';
import Swal from 'sweetalert2';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'app-advanced-treatment',
    templateUrl: './advanced-treatment.component.html',
    styleUrls: ['./advanced-treatment.component.css']
})
export class AdvancedTreatmentComponent implements OnInit {

    loading = false;
    loadingBtn = false;
    showPopup = false;
    modalBoxName = '';
    keyword = 'name';
    sno = 1;
    errorMsg: any = [];
    viewBox = false;
    showImage = true;
    show: boolean = false;
    defaultImage = 'assets/images/loader.gif';
    AdvancedTreatmentList:any;
    banner_id:any;
    title_one: any;
    title_two: any;
    description:any;
    headerLogo: any;
    logo_one:any;
    logo_two:any;
    logo_three:any;
    logo_four:any;
    logo_one_text:any;
    logo_two_text:any;
    logo_three_text:any;
    logo_four_text:any;

    constructor(
        private apiService: ApiService,
        private toastr: ToastrService,
        public router: Router,
        private imageService: ImageService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.getAdvancedTreatment();
    }

    getAdvancedTreatment() {
        this.loading = true;
        this.apiService.getData('getAdvancedTreatment').subscribe((data) => {
            this.AdvancedTreatmentList = data.data[0];
            this.banner_id = this.AdvancedTreatmentList.id
            this.title_one = this.AdvancedTreatmentList.title_one;
            this.title_two = this.AdvancedTreatmentList.title_two;
            this.description = this.AdvancedTreatmentList.description;
            this.headerLogo = this.AdvancedTreatmentList.image;
            this.logo_one = this.AdvancedTreatmentList.logo_one;
            this.logo_two = this.AdvancedTreatmentList.logo_two;
            this.logo_three = this.AdvancedTreatmentList.logo_three;
            this.logo_four = this.AdvancedTreatmentList.logo_four;
            this.logo_one_text = this.AdvancedTreatmentList.logo_one_text;
            this.logo_two_text = this.AdvancedTreatmentList.logo_two_text;
            this.logo_three_text = this.AdvancedTreatmentList.logo_three_text;
            this.logo_four_text = this.AdvancedTreatmentList.logo_four_text;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    apiCall(name, url, value: any = '') {
        this.apiService[name](url, value).subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.loadingBtn = false;
            } else {
                this.toastr.error(data.message);
                this.loadingBtn = false;
                this.errorMsg = data.data;
            }
            this.getAdvancedTreatment();
        });
    }

    updateHeader() {
        const value = {
            id: this.banner_id,
            title_one: this.title_one,
            title_two: this.title_two,
            description: this.description,
            logo_one_text: this.logo_one_text,
            logo_two_text: this.logo_two_text,
            logo_three_text: this.logo_three_text,
            logo_four_text: this.logo_four_text,
        }
        this.apiCall("update", "updateAdvancedTreatment", value);
    }

    closepopup() {
        this.show = false;
    }

    openPopup() {
        this.show = true;
    }
}
