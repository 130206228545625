import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UsersComponent } from './users/users.component';
import { SubCategoryImageComponent } from './components/sub-category-image/sub-category-image.component';
import { HomebannerComponent } from './home/homebanner/homebanner.component';
import { HomebannerImageComponent } from './home/homebanner-image/homebanner-image.component';
import { SocialMediaComponent } from './home/social-media/social-media.component';
import { TestimonyComponent } from './home/testimony/testimony.component';
import { UserTypesComponent } from './user-types/user-types.component';
import { WhoAreWeComponent } from './home/who-are-we/who-are-we.component';
import { HomePageTitleComponent } from './home/home-page-title/home-page-title.component';
import { FacilitiesComponent } from './home/facilities/facilities.component';
import { GalleryComponent } from './home/gallery/gallery.component';
import { GalleryImageComponent } from './home/gallery-image/gallery-image.component';
import { ConsultUsComponent } from './home/consult-us/consult-us.component';
import { AboutUsComponent } from './about-us/about-us/about-us.component';
import { StatementComponent } from './about-us/statement/statement.component';
import { AdvancedTreatmentComponent } from './facilities/advanced-treatment/advanced-treatment.component';
import { BestTreatmentComponent } from './facilities/best-treatment/best-treatment.component';
import { BestTreatmentImageComponent } from './facilities/best-treatment-image/best-treatment-image.component';
import { ContactUsComponent } from './home/contact-us/contact-us.component';
import { AppointmentComponent } from './home/appointment/appointment.component';
import { SeoHomePageComponent } from './seo/seo-home-page/seo-home-page.component';
import { SeoAboutUsPageComponent } from './seo/seo-about-us-page/seo-about-us-page.component';
import { SeoFacilitiesPageComponent } from './seo/seo-facilities-page/seo-facilities-page.component';
import { SeoContactUsPageComponent } from './seo/seo-contact-us-page/seo-contact-us-page.component';
import { ConsultUsNumberComponent } from './home/consult-us-number/consult-us-number.component';

const routes: Routes = [

    {path: 'sub-category-image/:id', component: SubCategoryImageComponent},
    {path: '', component: LoginComponent},
    {path: 'home-banner', component: HomebannerComponent},
    {path: 'home-banner-image/:id', component: HomebannerImageComponent},
    {path: 'who-are-we', component: WhoAreWeComponent},
    {path: 'home-page-title', component: HomePageTitleComponent},
    {path: 'facilities-type', component: FacilitiesComponent},
    {path: 'gallery', component: GalleryComponent},
    {path: 'gallery-image/:id', component: GalleryImageComponent},
    {path: 'testimony', component: TestimonyComponent},
    {path: 'consult-us', component: ConsultUsComponent},
    {path: 'consult-us-number', component: ConsultUsNumberComponent},
    {path: 'appointment-details', component: AppointmentComponent},
    {path: 'about-us', component: AboutUsComponent},
    {path: 'statement', component: StatementComponent},
    {path: 'advanced-treatment', component: AdvancedTreatmentComponent},
    {path: 'best-treatment', component: BestTreatmentComponent},
    {path: 'best-treatment-image/:id', component: BestTreatmentImageComponent},
    {path: 'contactus-details', component: ContactUsComponent},
    {path: 'social-media', component: SocialMediaComponent},
    {path: 'users-details', component: UsersComponent},
    {path: 'users-types', component: UserTypesComponent},
    {path: 'home-page-details', component: SeoHomePageComponent},
    {path: 'about-us-details', component: SeoAboutUsPageComponent},
    {path: 'facilities-details', component: SeoFacilitiesPageComponent},
    {path: 'contact-us-details', component: SeoContactUsPageComponent},
    {path: 'unauth', component: UnauthorizedComponent},
    {path: '**', component: PageNotFoundComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes,{ useHash: true }),],
    // imports: [RouterModule.forRoot(routes),],
    exports: [RouterModule]
})
export class AppRoutingModule { }
