import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ImageService } from 'src/app/services/image.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-seo-contact-us-page',
    templateUrl: './seo-contact-us-page.component.html',
    styleUrls: ['./seo-contact-us-page.component.css']
})
export class SeoContactUsPageComponent implements OnInit {

    loading = false;
    loadingBtn = false;
    showPopup = false;
    modalBoxName = '';
    sno = 1;
    errorMsg: any = [];
    viewBox = false;
    showImage = true;
    show: boolean = false;
    seoDetails:any;
    seo_id:any;
    internel_link: any;
    meta_title:any;
    meta_description: any;
    type:any;

    constructor(
        private apiService: ApiService,
        private toastr: ToastrService,
        public router: Router,
    ) { }

    ngOnInit() {
        this.type = 'contactus';
        this.getSeoDetails();
    }

    getSeoDetails() {
        this.loading = true;
        this.apiService.getData('getSeoDetails',this.type).subscribe((data) => {
            this.seoDetails = data.data[0];
            this.seo_id = this.seoDetails.seo_id;
            this.meta_title = this.seoDetails.contact_us_meta_title;
            this.meta_description = this.seoDetails.contact_us_meta_description;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    apiCall(name, url, value: any = '') {
        this.apiService[name](url, value).subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.loadingBtn = false;
            } else {
                this.toastr.error(data.message);
                this.loadingBtn = false;
                this.errorMsg = data.data;
            }
            this.getSeoDetails();
        });
    }

    updateSeoDetaiilsr() {
        const value = {
            seo_id: this.seo_id,
            contact_us_meta_title: this.meta_title,
            contact_us_meta_description: this.meta_description,
            type :this.type
        }
        this.apiCall("update", "updateSeoDetails", value);
    }

    closepopup() {
        this.show = false;
    }

    openPopup() {
        this.show = true;
    }
}

