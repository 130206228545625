import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { ImageService } from 'src/app/services/image.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

    errorMsg: any = [];
    sno = 1;
    showPopup = false;
    loading = false;
    contactusList: any = [];
    description :any;
    editBannerId = '';
    modalBoxName;
    loadingBtn = false;
    viewBox = false;
    showImage = true;

    constructor(
        private apiService: ApiService,
        private toastr: ToastrService,
        private imageService: ImageService
    ) { }

    ngOnInit() {
        this.index();
    }

    index() {
        this.loading = true;
        this.apiService.index('getContactUsDetails').subscribe(data => {
            this.contactusList = data.data;
            this.loading = false;
        });
    }

    show(id) {
        this.apiService.show('contactUs/' + id).subscribe((data) => {
            const value = data.data;
            this.description = value.comments;
        });
    }

    apiCall(name, url, value: any = '') {
        value = name === 'store' || name === 'update' ? {
        } : value;
        this.apiService[name](url, value).subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.loadingBtn = false;
                this.popUpClose();
                this.index();
            } else {
                this.toastr.error(data.message);
                this.loadingBtn = false;
                this.errorMsg = data.data;
            }
        });
    }

    openModalBox(id = '') {
        if (id) {
            this.modalBoxName = 'View';
            this.show(id);
        }
        this.showPopup = true;
    }

    popUpClose() {
        this.showPopup = false;
        this.errorMsg = [];
        this.viewBox = false;
    }
}
