import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ImageService } from 'src/app/services/image.service';
import Swal from 'sweetalert2';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';


@Component({
    selector: 'app-who-are-we',
    templateUrl: './who-are-we.component.html',
    styleUrls: ['./who-are-we.component.css']
})
export class WhoAreWeComponent implements OnInit {

    loading = false;
    loadingBtn = false;
    showPopup = false;
    modalBoxName = '';
    sno = 1;
    errorMsg: any = [];
    viewBox = false;
    showImage = true;
    show: boolean = false;
    whoWeAreDetails:any;
    banner_id:any;
    header: any;
    description:any;
    points: any;
    headerLogo: any;

    constructor(
        private apiService: ApiService,
        private toastr: ToastrService,
        public router: Router,
        private imageService: ImageService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.getWhoAreWe();
    }

    getWhoAreWe() {
        this.loading = true;
        this.apiService.getData('getWhoAreWe').subscribe((data) => {
            this.whoWeAreDetails = data.data[0];
            this.banner_id = this.whoWeAreDetails.id
            this.header = this.whoWeAreDetails.header;
            this.description = this.whoWeAreDetails.description;
            this.points = this.whoWeAreDetails.points;
            this.headerLogo = this.whoWeAreDetails.image;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    apiCall(name, url, value: any = '') {
        this.apiService[name](url, value).subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.loadingBtn = false;
            } else {
                this.toastr.error(data.message);
                this.loadingBtn = false;
                this.errorMsg = data.data;
            }
            this.getWhoAreWe();
        });
    }

    updateHeader() {
        const value = {
            id: this.banner_id,
            header: this.header,
            description: this.description,
            points: this.points,
        }
        this.apiCall("update", "updateWhoAreWe", value);
    }

    closepopup() {
        this.show = false;
    }

    openPopup() {
        this.show = true;
    }
}
