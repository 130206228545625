import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    forgotpassword = false;
    newpwd = false;
    loading: boolean;
    userName: any;
    password: any;
    usertype: any = 'user';
    userNamePwd:any = '';
    userOTPPwd:any = '';
    newPassword:any = '';
    newConfirmPassword:any = '';
    sendotp = false;
    showOtpBox = false;
    UserTypes:any;
    constructor(private route: Router, private apiService: ApiService, private toastr: ToastrService) { }

    ngOnInit() {
        localStorage.clear();
    }

    userType(type) {
        this.usertype = type;
    }

    login() {
        this.loading = true;
        if (this.userName && this.password) {
            const value = { user_name: this.userName, password: this.password};
            this.apiService.postData(value, 'loginUser').subscribe(data => {
                if (data.error === false) {
                    this.toastr.success(data.message);
                    localStorage.setItem('user_name', data.data.user_name);
                    localStorage.setItem('user_token', data.data.token);
                    localStorage.setItem('user_id', data.data.id);
                    localStorage.setItem('user_type', data.data.usertypes[0].type_name);
                    this.UserTypes = data.data.usertypes[0].type_name;
                    // localStorage.setItem('role_name', 'Admin');
                    this.loading = false;
                    if(this.UserTypes == 'Super Admin'){
                        this.route.navigate(['users-types']);
                    }else {
                        this.route.navigate(['home-banner']);
                    }
                } else {
                    this.toastr.error(data.message);
                    this.loading = false;
                }
            }, (error) => {
                this.toastr.error('Something went wrong try after some time.');
                this.loading = false;
            });
        } else {
            this.toastr.error('Fill the Madatory Params to Proceed');
            setTimeout(() => {
                this.loading = false;
            }, 200);
        }
    }

    showForgotPassword(type) {
        this.forgotpassword = type;
    }

    forgotPassword() {
        if(!this.userNamePwd) {
            this.toastr.warning('Enter UserName Or Email To Continue');
        } else {
            this.sendotp = true;
            const value = { user_name_or_email: this.userNamePwd};
            this.apiService.postData(value,'forgotPassword').subscribe((data)=> {
                if(!data.error) {
                    this.toastr.success(data.message);
                    this.sendotp = false;
                    this.showOtpBox = true;
                } else {
                    this.toastr.warning(data.message);
                }
            });
        }
    }

    ResetPassword() {
        if(!this.userNamePwd) {
            this.toastr.warning('Enter UserName Or Email To Continue');
        } else if( this.userOTPPwd == null || this.userOTPPwd == ""){
            this.toastr.warning('Enter Reset Password');
            return false;
        } else {
            const value = { user_name_or_email: this.userNamePwd, password: this.userOTPPwd};
            this.apiService.postData(value,'ResetPassword').subscribe((data)=> {
                if(!data.error) {
                    this.toastr.success(data.message);
                    this.sendotp = false;
                    this.showOtpBox = false;
                    // this.showForgotPassword();
                    this.showlogin();
                    this.userNamePwd='';
                    this.userOTPPwd='';
                } else {
                    this.toastr.warning(data.message);
                }
            });
        }
    }

    showlogin() {
        this.forgotpassword = this.newpwd = false;
        this.showOtpBox = false;
        this.userNamePwd='';
        this.userOTPPwd='';
        this.userName='';
        this.password='';
    }
}
