import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    constructor(
        private apiService: ApiService,
        private router: Router
    ) { }

    ngOnInit() {
    }

    logout() {
        localStorage.clear();
        location.reload();
        this.router.navigate(['']);
    }
}
